import React from 'react'
import { Link } from 'gatsby'

import Hero from "../components/hero" 
import Layout from "../components/layout"
import MaxWidth from "../components/maxWidth"
import SEO from "../components/seo"

const ContactThank = () => (
    <>
        <Hero>
            <h1>Thank You!</h1>
            <p>We have received your submission and will be in contact soon.</p>
        </Hero>
     <Layout>
     <SEO title="Thank You for Contacting Us" keywords={[`EAP Contact`, `Employee Assistance Contact`, `EAP Help`, `employee help`]} description="Corporate EAP Resources has been helping Boston area businesses &amp; individuals for over 30 years."/>
        <MaxWidth>
          <p>We will be in contact soon. In the meantime, please find more information below.</p>
          <p><Link to='/'>Home</Link><br/>
          Home page of EAP.help.</p>
          <p><Link to='/for-employees'>Information for Employees</Link><br/>
          Information beneficial for Employees</p>
          <p><Link to='/for-employers'>Information for Employers</Link><br/>
          Information beneficial for Employers</p>
          <p><Link to='/about-referrals'>Referrals</Link><br/>
          How self or management referrals work</p>
          <p><Link to='/about-referrals'>Privacy</Link><br/>
          Our privacy information page</p>
          <p><Link to='/about-referrals'>Contact Us</Link><br/>
          More ways to contact Corporate EAP Resources</p>
        </MaxWidth>
    </Layout>
	 </>
)

export default ContactThank